require.config({
    paths: {
        jquery: '../bower_components/jquery/dist/jquery.min',
        bootstrap: '../vendor/bootstrap/3.0.17/js/sass-bootstrap.min',
        owl: '../vendor/owl.carousel/1.31/owl.carousel.min',
        PageView: 'PageView/view',
        carouFredSel: '../vendor/caroufredsel/jquery.carouFredSel-6.2.1-packed',
        cookieCutter: '../vendor/cookie-cutter/cookie-cutter',
        froogaloop: '../vendor/froogaloop/froogaloop',
        slidemenu: 'modules/slidemenu',
    },
    shim: {
        bootstrap: {
            deps: ["jquery"]
        },
        owl: {
            deps: ['jquery']
        },
        carouFredSel: {
            deps: ['jquery']
        },
        cookieCutter: {
            deps: ['jquery']
        },
        froogaloop: {
            deps: ['jquery'],
            exports: '$f'
        },
        slidemenu: {
            deps: ['jquery']
        }
    }
});

require(['jquery', 'bootstrap', 'slidemenu', 'modules/linkifyObfuscatedEmails'], function ($, Bootstrap, slideMenu, linkifyObfuscatedEmails) {

    $("#slide-nav").slideMenu();

    //obfuscateEmails.init(); 
    
    // TODO - do we have a modular system for these kind of helper functions?
    function linkifyObfuscatedEmails() {
        $('.obfuscate-email').each(function() {
            var email = $(this);
            $('span', email).remove();
            email.html('<a href="mailto:' + email.html() + '">' + email.html() + '</a>');
        });
    }
    linkifyObfuscatedEmails();

});

define("main", function(){});

